var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DynamicScroller',{staticClass:"scroller px-0 mx-0",attrs:{"items":_vm.items,"min-item-size":54},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('v-card-title',{staticClass:"justify-center"},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})])]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[item.title],"data-index":index},on:{"visible":function($event){return _vm.log('item visible')}}},[_c('v-expansion-panels',{staticClass:"mb-2",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(item.title)}})]),_c('v-expansion-panel-content',[_c('v-divider',{staticClass:"mb-3"}),_c('div',{domProps:{"innerHTML":_vm._s(item.answer)}})],1)],1),_c('v-btn',{staticStyle:{"right":"-0.5em","top":"-0.5em"},attrs:{"right":"","fixed":"","icon":"","color":_vm.bookmarks.includes(item.id)
              ? _vm.$store.getters.isThemeDark
                ? "yellow"
                : "orange darken-3"
              : _vm.$store.getters.isThemeDark
              ? "grey darken-3"
              : "grey lighten-2"},on:{"click":function($event){$event.stopPropagation();return _vm.handleBookmarkClick(item)}}},[_c('v-icon',[_vm._v("mdi-bookmark")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }