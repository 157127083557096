<template>
  <v-row v-if="true" dense no-gutters class="justify-center">
    <v-col cols="12">
      <v-card class="mb-4 primary darken-4" dark>
        <v-card-title>
          <v-btn icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <div class="text-right">
            <div
              class="caption"
              v-if="$route.params.category != $route.params.subject"
            >
              {{ $route.params.category }}
            </div>
            <div class="title">
              {{ $route.params.subject }}
            </div>
          </div>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <scroller
        :canLoadMore="!canLoadMore"
        :items="items"
        @reachedEnd="reachedEnd"
        @remove="(itm) => handleRemove(itm)"
        @removed="(itm) => handleRemove(itm)"
        @clicked="handleClick"
      />
      <div class="text-center">
        <v-progress-circular v-if="loading" indeterminate />
      </div>
    </v-col>
    <v-dialog width="500" v-model="showSignupDialog">
      <v-card class="blue darken-4" dark>
        <v-card-title>
          {{ $lang("SIGNUPLOGINTOCONTINUE") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$router.push({ name: 'login' })">{{
            $lang("login")
          }}</v-btn>
          <v-btn text outlined @click="$router.push({ name: 'register' })">{{
            $lang("signup")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";
import scroller from "./scroller.vue";

export default {
  components: { scroller },
  name: "learn-questions",
  data() {
    return {
      loading: false,
      benched: 0,
      items: [],
      page: 1,
      lastPage: 1,
      canLoadMore: true,
      showNewDialog: false,
      showSignupDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    "authUser.id"(val) {},
    items(val) {
      if (this.authUser && this.authUser.id) {
        return;
      }
      if (val && val.length > 20) {
        this.showSignupDialog = true;
      } else {
        this.showSignupDialog = false;
      }
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        name: "learn-questions",
        params: { subject: this.$route.params.subject, category: item.title },
      });
    },

    handleRemove(item) {},

    reachedEnd() {
      this.loadMoreFromApi();
    },
    fetchItems(page = 1) {
      this.loading = true;
      return axios
        .get(
          `learn/${this.$route.params.subject}/${this.$route.params.category}/?perPage=10&page=${page}`
        )
        .then((res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          this.canLoadMore = false;
          return Promise.reject(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadMoreFromApi() {
      if (this.page > this.lastPage) {
        return;
      }
      return this.fetchItems(this.page)
        .then((data) => {
          this.lastPage = data.lastPage;
          this.items.push(...data.data);
          this.page++;
        })
        .catch((err) => {
          this.canLoadMore = false;
        });
    },
  },
  mounted() {
    document.title = `${this.$route.params.category} - ${this.$route.params.subject} | vikarn.com`;
    // this.loadMoreFromApi();
  },
};
</script>

